<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BOD INCOME - MONTHLY ALLOWANCE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form" class="multi-col-validation">
              <v-checkbox v-model="is_employee" label="Is Employee" hide-details class="me-3 mt-1"
                          @change="change_list_of_names"></v-checkbox>
              <v-select
                v-model="month_of_id"
                class="mx-2"
                dense
                outlined
                label="Month Of"
                :items="month_of_items"
                item-value="id"
                item-text="month_of"
                :rules="rules.combobox_rule"
                @change="selected_month"
              ></v-select>
              <v-select
                v-model="bod_name"
                class="mx-2 mt-2"
                dense
                outlined
                label="Name"
                :items="list_of_names"
                item-text="name"
                item-value="name"
                :rules="rules.combobox_rule"
                @change="selected_bod_name"
              ></v-select>
              <v-text-field
                @change="calculate"
                @keyup="calculate"
                class="mx-2"
                v-model="total_gross"
                label="Total Gross"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>
              <v-text-field
                @change="calculate"
                @keyup="calculate"
                class="mx-2"
                v-model="allowances"
                label="5th/20th Allowance"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>
              <v-text-field class="mx-2" v-model="net_income" label="Net Income" dense outlined
                            readonly></v-text-field>
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn class="w-full" color="primary" @click="save_payment()" v-if="!saving_data">
                  Save
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
              <v-col cols="12" md="12" v-if="data_items.length > 0 && can_final">
                <v-btn class="w-full" color="success" @click="final_allowance()"
                       v-if="!saving_data"> Final
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-form>
          </v-flex>
          <v-flex xs12 md9 class="mx-2">
            <h2>Total: P {{ total_amount }}</h2>
            <h3 v-if="data_items.length>0">Print |
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
                v-if="!printt"
              >
                {{icons.mdiPrinter}}
              </v-icon>
              <v-progress-circular
                :size=25
                :width="5"
                color="success"
                indeterminate
                v-if="printt"
              ></v-progress-circular>
            </h3>
            <v-data-table dense :headers="headers" :items="data_items">
              <template v-slot:top v-if="is_employee">
                <h5 v-if="!saving_data" class="mx-2">ADD / REMOVE EMPLOYEE
                  <v-icon
                    class="mr-2"
                    color="info"
                    @click="change_status()"
                  >
                    {{icons.mdiAccountGroupOutline}}
                  </v-icon>
                </h5>

                <v-progress-circular
                  :size=50
                  :width="5"
                  color="success"
                  indeterminate
                  v-if="saving_data"
                ></v-progress-circular>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ formatPrice(item.total_gross) }}
                  </td>
                  <td>
                    {{ formatPrice(item.allowances) }}
                  </td>
                  <td>
                    {{ formatPrice(item.net_income) }}
                  </td>
                  <td>
                    {{ deductions_calculation(item) }}
                  </td>
                  <td>
                    {{ net_calculation(item) }}
                  </td>
                  <td>
                    {{ item.is_final === 1 ? 'Final' : 'Pending' }}
                  </td>
                  <td>
                    <v-icon v-if="item.is_final === 0" class="mr-2" color="error"
                            @click="delete_allowances(item)">
                      {{ icons.mdiDelete }}
                    </v-icon>
                    <v-icon v-if="item.is_final === 0" class="mr-2" color="info"
                            @click="add_deductions(item)">
                      {{ icons.mdiCashMinus }}
                    </v-icon>
                    <v-icon class="mr-2" color="success" @click="view_soa(item)">
                      {{ icons.mdiEye }}
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
    <v-dialog v-model="is_dissolve" persistent max-width="50%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">DEDUCTIONS</h4></v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form ref="form2" class="multi-col-validation">
            <v-col cols="12">
              <v-select
                v-model="deduction_particulars"
                class="mt-2"
                dense
                label="Particulars"
                :items="['SSS Loan','Pag-Ibig Loan','Coop Save','Coop Loan','Cash Advance', 'Auto Loan', 'Loan', 'Other Recievable', 'Others']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="deduction_amount"
                label="Amount"
                dense
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="deduction_details"
                label="Details"
                dense
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-show="alert_customize">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message_customize }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
          </v-form>
          <v-data-table dense :headers="headers2" :items="data_items2">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.particulars }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ item.details }}
                </td>
                <td>
                  <v-icon class="mr-2" color="error" @click="delete_deductions_data(item)">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="add_deductions_data"> Save Changes</v-btn>
      <v-btn color="error" @click="is_dissolve = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_add_remove_employee" persistent max-width="50%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ADD / REMOVE EMPLOYEE</h4></v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form ref="form2" class="multi-col-validation">
            <v-col cols="12">
              <v-autocomplete
                v-model="search"
                :items="search_items"
                :loading="isLoading"
                @keyup.enter="searching($event.target.value)"
                hide-no-data
                item-text="name"
                item-value="id"
                label="Search"
                placeholder="Press Enter to Search "
                :prepend-icon="icons.mdiAccountSearchOutline"
                @change="get_search_items_info"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" v-show="alert_customize">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message_customize }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-btn class="w-full" color="info" @click="add_new_employee()"
                   v-if="!saving_data"> Save Changes
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-form>
          <v-data-table dense :headers="headers3" :items="list_of_names">
            <template v-slot:item.action="{ item }">
              <tr>
                <td>
                  <v-icon class="mr-2" color="error" @click="delete_employee_from_allowance(item)">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-btn color="error" @click="is_add_remove_employee = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiDelete,
    mdiCashMinus,
    mdiEye,
    mdiAccountGroupOutline,
    mdiAccountSearchOutline,
    mdiPrinter,
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      printt: false,
      isLoading: false,
      is_add_remove_employee: false,
      is_employee: false,
      is_dissolve: false,
      alert_customize: false,
      alert_message_customize: '',
      deduction_details: '',
      deduction_amount: '',
      deduction_particulars: '',
      alert: false,
      saving_data: false,
      alert_message: '',
      search: '',
      data_items: [],
      data_items2: [],
      month_of_items: [],
      search_items: [],
      new_employee_id: 0,
      bod_allowance_id: 0,
      employee_id: 0,
      total_amount: '',
      net_income: '',
      deductions: '',
      allowances: '',
      total_gross: '',
      bod_name: '',
      month_of_id: '',
      can_final: false,
      list_of_names: [{employee_id: 3, name: 'ARROCO, ALBERT'}, {
        employee_id: 1,
        name: 'CELOMIN, JULIUS'
      }, {employee_id: 4, name: 'FERNANDEZ, JOB'}, {
        employee_id: 2,
        name: 'HILARIO, ALBERT'
      }, {employee_id: 5, name: 'TAMPUS, EMPTHONY'}],
      headers: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Gross', value: 'tin', sortable: false},
        {text: 'Allowances', value: 'tin', sortable: false},
        {text: 'Net', value: 'tin', sortable: false},
        {text: 'Deductions', value: 'tin', sortable: false},
        {text: 'Net Receivables', value: 'tin', sortable: false},
        {text: 'Status', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      headers2: [
        {text: 'Particulars', value: 'name', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Details', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'employee_id', sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiDelete,
          mdiPrinter,
          mdiCashMinus,
          mdiEye,
          mdiAccountGroupOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bod_allowances', [
        'initialize_bod_allowances',
        'bod_allowances_delete',
        'register_bod_allowances',
        'final_bod_allowances',
        'update_employee_have_allowance',
        'list_of_employee_have_special_allowance',
      ]),
      ...mapActions('employee', ['search_all_active_employee']),
      ...mapActions('bod_allowances_deductions', [
        'register_bod_allowances_deductions',
        'delete_bod_allowances_deductions',
      ]),
      async change_list_of_names() {
        this.list_of_names = [{employee_id: 3, name: 'ARROCO, ALBERT'}, {
          employee_id: 1,
          name: 'CELOMIN, JULIUS'
        }, {employee_id: 4, name: 'FERNANDEZ, JOB'}, {
          employee_id: 2,
          name: 'HILARIO, ALBERT'
        }, {employee_id: 5, name: 'TAMPUS, EMPTHONY'}]
        if (this.is_employee) {
          await this.list_of_employee_have_special_allowance()
            .then(response => {
              this.list_of_names = response.data
            })
            .catch(error => {
              console.log(error)
            })
          // this.list_of_names = [
          //   {employee_id: 188, name: 'LAINGO, HAROLD'}, {
          //     employee_id: 106,
          //     name: 'CABARDE, ANALIE'
          //   }, {employee_id: 337, name: 'OBILLE, MAICA MAE'}, {
          //     employee_id: 161,
          //     name: 'BARUELA, ROANNE'
          //   },
          //   {employee_id: 267, name: 'CRUMB, JHOAN'}, {
          //     employee_id: 429,
          //     name: 'RODA, ERROL DAVE'
          //   }, {employee_id: 430, name: 'INTAN, ROLDAN'}, {
          //     employee_id: 417,
          //     name: 'VALENTIN, PAULYN'
          //   }, {employee_id: 162, name: 'CALAWEN, LIANNE'},
          //   {employee_id: 206, name: 'MERIDA, LENIE'}, {
          //     employee_id: 357,
          //     name: 'CALOG, IRENE'
          //   }, {employee_id: 187, name: 'ADRIATICO, ELBERT CLINTON'}, {
          //     employee_id: 1051,
          //     name: 'LOPEZ, EMMANUEL'
          //   }, {employee_id: 141, name: 'LAINGO, JAN LUIS'},]
        }
        await this.initialize_data()
        await this.selected_month()
      },
      searching(value) {
        this.isLoading = true
        this.search_items = []
        this.search_all_active_employee({
          search_word: value,
        }).then(response => {
          this.search_items = response.data
          this.search = ''
          this.isLoading = false
        })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(value)
          if (index != -1) {
            this.new_employee_id = this.search_items[index].id
          } else {
            this.search = ''
            this.search_items = []
          }
        } else {
          this.search_items = []
          this.search = ''
        }
      },
      change_status() {
        this.is_add_remove_employee = true
      },
      async add_new_employee() {
        this.alert_customize = false
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('employee_id', this.new_employee_id)
          data.append('value', 1)
          await this.update_employee_have_allowance(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: 'SUCCESSFULLY ADDED!!',
              })
            })
            .catch(error => {
              console.log(error)
            })
          await this.change_list_of_names()
        } else {
          this.alert_customize = true
          this.alert_message_customize = 'Please Fill up the field/s'
        }
      },
      async delete_employee_from_allowance(value) {
        const data = new FormData()
        data.append('employee_id', value.employee_id)
        data.append('value', 0)
        await this.update_employee_have_allowance(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: 'SUCCESSFULLY DELETED!!',
            })
          })
          .catch(error => {
            console.log(error)
          })

        await this.change_list_of_names()
      },
      selected_bod_name() {
        var index = this.list_of_names
          .map(function (x) {
            return x.name
          })
          .indexOf(this.bod_name)
        if (index != -1) {
          this.employee_id = this.list_of_names[index].employee_id
        }
      },
      async initialize_data() {
        await this.initialize_bod_allowances({
          is_employee: this.is_employee ? 1 : 0
        })
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_month() {
        this.can_final = false
        var index = this.month_of_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.month_of_id)
        if (index != -1) {
          var fin = false
          this.data_items = this.month_of_items[index].bod_allowance
          var tot = 0
          this.data_items.forEach(function (item) {
            tot += parseFloat(item.net_income)
            fin = item.is_final === 0
          })
          this.can_final = fin
          this.total_amount = this.formatPrice(tot)
        }
      },
      deductions_calculation(item) {
        var amount = 0
        item.deductions.forEach(function (item) {
          amount += parseFloat(item.amount)
        })
        return this.formatPrice(amount)
      },
      net_calculation(item) {
        var amount = 0
        item.deductions.forEach(function (item) {
          amount += parseFloat(item.amount)
        })
        return this.formatPrice(parseFloat(item.net_income) - parseFloat(amount))
      },
      delete_allowances(item) {
        const data = new FormData()
        data.append('id', item.id)
        this.bod_allowances_delete(data)
          .then(() => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: 'DELETE SUCCESSFULLY',
            })
            this.selected_month()
          })
          .catch(error => {
            console.log(error)
          })
      },
      async add_deductions(item) {
        this.bod_allowance_id = item.id
        this.is_dissolve = true
        this.data_items2 = item.deductions
      },
      async add_deductions_data() {
        this.alert_customize = false
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('particulars', this.deduction_particulars)
          data.append('bod_allowance_id', this.bod_allowance_id)
          data.append('amount', this.deduction_amount)
          data.append('details', this.deduction_details.toUpperCase())
          this.register_bod_allowances_deductions(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: 'DEDUCTIONS SUCCESSFULLY ENCODED!!',
              })
              this.data_items2 = response.data
            })
            .catch(error => {
              console.log(error)
            })
          await this.initialize_data()
          await this.selected_month()
        } else {
          this.alert_customize = true
          this.alert_message_customize = 'Please Fill up the field/s'
        }
      },
      async delete_deductions_data(value) {
        const data = new FormData()
        data.append('id', value.id)
        data.append('bod_allowance_id', value.bod_allowance_id)
        this.delete_bod_allowances_deductions(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'error',
              text: 'DELETE SUCCESSFULLY',
            })
            this.data_items2 = response.data
          })
          .catch(error => {
            console.log(error)
          })
        await this.initialize_data()
        await this.selected_month()
      },
      calculate() {
        this.net_income = parseFloat(this.total_gross) * 0.02 - parseFloat(this.allowances)
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async save_payment() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('name', this.bod_name)
          data.append('employee_id', this.employee_id)
          data.append('total_gross', this.total_gross)
          data.append('allowances', this.allowances)
          data.append('gross_income', this.total_gross)
          data.append('net_income', this.net_income)
          data.append('month_of_id', this.month_of_id)
          data.append('is_employee', this.is_employee ? 1 : 0)
          this.register_bod_allowances(data)
            .then(response => {
              this.saving_data = false
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
            })
          await this.initialize_data()
          await this.selected_month()
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      async final_allowance() {
        this.saving_data = true
        this.alert = false
        const data = new FormData()
        data.append('month_of_id', this.month_of_id)
        data.append('is_employee', this.is_employee ? 1 : 0)
        this.final_bod_allowances(data)
          .then(() => {
            this.saving_data = false
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'REPORT SUCCESSFULLY FINAL',
            })
          })
          .catch(error => {
            console.log(error)
          })
        await this.initialize_data()
        await this.selected_month()
      },
      async view_soa(item) {
        var imgData = this.company_logo

        var payments_array = []
        var widths = []

        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing

        widths = [100, 80, 300]
        payments_array.push([
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
          {text: 'Details', alignment: 'left', style: 'main_info'},
        ])

        var tot = 0
        item.deductions.forEach(function (item) {
          tot += parseFloat(item.amount)
          payments_array.push([
            {
              text: item.particulars,
              alignment: 'left',
              style: 'main_info',
            },
            {
              text:
                item.amount === 0
                  ? ''
                  : (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'main_info',
            },
            {
              text: item.details,
              alignment: 'left',
              style: 'main_info',
            },
          ])
        })

        info_array = [
          {
            columns: [
              {
                style: {
                  fontSize: 13,
                },
                text: [
                  'STATUS: ',
                  {
                    text: item.is_final == 1 ? 'FINAL' : 'PENDING',
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                    },
                  },
                ],
              },
              {
                style: {
                  fontSize: 13,
                },
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: {
                  fontSize: 13,
                },
                text: [
                  'TOTAL GROSS: ',
                  {
                    text: (item.total_gross / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            text: ' ',
          },
          {
            columns: [
              {
                style: {
                  fontSize: 13,
                  bold: true,
                },
                text: ['COMPUTATION'],
              },
            ],
          },
          {
            text: ' ',
          },
          {
            columns: [
              {
                style: {
                  fontSize: 13,
                },
                text: [
                  'GROSS: ',
                  {
                    text: ((parseFloat(item.allowances) + parseFloat(item.net_income)) / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: {
                  fontSize: 13,
                },
                text: [
                  'LESS: ',
                  {
                    text: (item.allowances / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      color: 'red',
                    },
                  },
                ],
              },
              {
                style: {
                  fontSize: 13,
                  alignment: 'center',
                  bold: true,
                },
                alignment: 'left',
                text: ['5th AND 20th ALLOWANCE'],
              },
              {
                style: 'main_info',
                alignment: 'left',
                text: [''],
              },
            ],
          },
          {
            columns: [
              {
                style: {
                  fontSize: 13,
                },
                text: [
                  'NET: ',
                  {
                    text: (item.net_income / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            text: ' ',
          },
          {
            columns: [
              {
                style: {
                  fontSize: 11,
                },
                text: [
                  (this.is_employee ? 'EMPLOYEE' : 'BOD') + ' NAME: ',
                  {
                    text: item.name,
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            text: ' ',
          },
          {
            columns: [
              {
                style: {
                  fontSize: 11,
                },
                text: [
                  'AMOUNT RECEIVABLE: ',
                  {
                    text: (item.net_income / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    style: {
                      fontSize: 13,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            text: ' ',
          },
          {
            columns: [
              {
                style: 'main_info',
                alignment: 'left',
                text: [''],
              },
              {
                style: {
                  fontSize: 16,
                  alignment: 'center',
                  bold: true,
                },
                text: ['DEDUCTIONS'],
              },
              {
                style: 'main_info',
                alignment: 'left',
                text: [''],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: (this.is_employee ? 'EMPLOYEE' : 'BOD') + ' ALLOWANCE REPORT',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              text: ' ',
            },
            {
              columns: [
                {
                  style: {
                    fontSize: 11,
                  },
                  text: [
                    'TOTAL DEDUCTIONS: ',
                    {
                      text: (tot / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      style: {
                        fontSize: 13,
                        alignment: 'center',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: {
                    fontSize: 11,
                  },
                  text: [
                    'TOTAL RECEIVABLE: ',
                    {
                      text: ((parseFloat(item.net_income) - tot) / 1)
                        .toFixed(2)
                        .replace(',', '.')
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      style: {
                        fontSize: 13,
                        alignment: 'center',
                        color: 'green',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  columns: [''],
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        widths = [150, 50, 55, 50, 55, 55, 50, 50, 50]
        payments_array.push([
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'Gross', alignment: 'left', style: 'main_info'},
          {text: 'Allowances', alignment: 'left', style: 'main_info'},
          {text: 'Net', alignment: 'left', style: 'main_info'},
          {text: 'Deductions', alignment: 'left', style: 'main_info'},
          {text: 'Net Receivables', alignment: 'left', style: 'main_info'},
          {text: 'Status', alignment: 'left', style: 'main_info'},
        ])

        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          var amount33 = 0
          item.deductions.forEach(function (item) {
            amount33 += parseFloat(item.amount)
          })
          var amounttt = 0
          item.deductions.forEach(function (item) {
            amounttt += parseFloat(item.amount)
          })
          var nee=(parseFloat(item.net_income) - parseFloat(amounttt));
          payments_array.push([
            {
              text: item.name,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.total_gross / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.allowances / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.net_income / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (amount33 / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (nee / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.is_final === 1 ? 'Final' : 'Pending'  ,
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'TYPE OF ALLOWANCE: ',
                  {
                    text: this.is_employee?'EMPLOYEE ALLOWANCE':'BOD ALLOWANCE',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[this.month_of_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.month_of_id)].month_of,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image: this.position === 'ADMIN' ? this.admin :
                    (this.position === 'TREASURY' ? this.trea :
                      (this.position === 'ACCOUNTING' ? this.accounting :
                        (this.position === 'AMD' ? this.amd :
                          (this.position === 'PROPERTY CUSTODIAN' ? this.prot :
                            (this.position === 'HUMAN RESOURCE' ? this.hr :
                              (this.position === 'BOOKKEEPING' ? this.book :
                                (this.position === 'CMD' ? this.accounting : imgData))))))),
                  width: 60,
                  height: 54,
                  style: 'logo'
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'MONTHLY ALLOWANCE',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {

              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (FINANCE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left'
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},

              ],
            };
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      }
    },
  }
</script>
